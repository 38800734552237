import { printReceipt } from "TempUtils";

import { errorConstants, PaymentMethod, paymentTabsHeaderTexts, paymentTabStatusTypes } from "Constants";
import { getSingleModification } from "TempUtils/modsHelpers";
import { getMenuBundleModificationsToUse } from "Utils/bundleHelpers";
import { get, round, uniqBy } from "lodash";
import {
    makePostPonedOrder,
    buildOrderInput,
    makeSwishPayment,
    makeCardPayment,
    makeCashPayment,
    makeInvoicePayment,
    createDTOrder,
    makePostponeExtendOrder
} from "./paymentHelpersT";
import { PuckModal } from "../components/PuckModal/PuckModal";
import { BundleItemOperation } from "Providers";
import { getCartTotalOriginalPrice } from "./priceUtils";
import { makeManualCardPayment } from "./paymentHelpersT/makeManualCardPayment";
import { DISCOUNT_TYPE } from "Types";
import { pingReceiptPrinter } from "./paymentHelpersT/handleCreatePosOrderHelpers";
import { handleFailedOrder } from ".";
import { posStore, orderWindowsStore, modalStore } from "Stores";
import { PaymentStatus } from "../../../../../generated/graphql";
import { getPickupDisplayMobileUrl } from "Utils";

export const ORDER_ALREADY_PAID = "ORDERN ÄR REDAN BETALD";

/**
 * check if bundle category is reached
 *
 * @param {object} bundleProductCategory the bundle category
 * @param {object} selectedBundleProductItems contains the selected bundle items
 * @returns {boolean}
 */
export const limitReached = (bundleProductCategory, selectedBundleProductItems) => {
    const selectedCategoryProducts = selectedBundleProductItems.filter(
        bundleItem => bundleItem.bundleProductCategoryId === bundleProductCategory.id && bundleItem.refProductId
    ).length;
    return selectedCategoryProducts === bundleProductCategory.limit;
};

/**
 * if there is only one item in bundle category, preselect it
 *
 * @param {object} bundleProductCategory the bundle category
 * @param {object} menuBundleProduct the menuproduct
 * @param {object} selectedBundleProductItems contains the selected bundle items
 * @param {object | array} refProducts a map of all the refproducts
 * @param {function} addBundleItemToBundleProduct the function to add bundle item to cart
 */
export const preSelectBundleItems = (
    bundleProductCategory,
    menuBundleProduct,
    selectedBundleProductItems,
    refProducts,
    addBundleItemToBundleProduct,
    outOfStockIds,
    allowItemToggle // cant default to false since online will break
) => {
    if (
        bundleProductCategory.refProductIdList.length === 1 &&
        !limitReached(bundleProductCategory, selectedBundleProductItems)
    ) {
        const singleRefId = bundleProductCategory.refProductIdList[0];
        const refProduct = Array.isArray(refProducts)
            ? refProducts.find(refProduct => refProduct.id === singleRefId)
            : refProducts[singleRefId];
        if (!outOfStockIds.has(refProduct.id)) {
            const modificationsToUse = getMenuBundleModificationsToUse(refProduct, menuBundleProduct);
            if (modificationsToUse) {
                const singleModification = getSingleModification(modificationsToUse);
                if (singleModification) {
                    addBundleItemToBundleProduct(
                        refProduct,
                        bundleProductCategory,
                        singleModification,
                        allowItemToggle,
                        null,
                        BundleItemOperation.ADD
                    );
                }
            } else {
                addBundleItemToBundleProduct(
                    refProduct,
                    bundleProductCategory,
                    null,
                    allowItemToggle,
                    null,
                    BundleItemOperation.ADD
                );
            }
        }
    }
};

/**
 * Function for handling failed cloud purchases
 *
 * @param {function} updateActivePaymentTabStatus function to update paymenttab status
 * @param {string} terminalId The terminalId
 * @param {string} orderWindowId The active orderWindows Id
 * @param {string} errorMessage The error message if exists
 * @param {function} resetTerminalStateById reset terminal state to idle
 */
export const cloudCancelledUpdateWindow = async (
    updateActivePaymentTabStatus,
    terminalId,
    orderWindowId,
    errorMessage,
    resetTerminalStateById
) => {
    updateActivePaymentTabStatus(orderWindowId, true, "Avbrutet", errorMessage, paymentTabStatusTypes.CANCELLED);
    // setTimeout(() => {
    //     resetTerminalStateById(terminalId);
    // }, 5050);
};

/**
 * Function for cloud purchase
 *
 * @param {function} savePaidAttemptCloudPostponedOrder mutation to handle the postponed purchase
 * @param {object} posOrderInput the order input to mutation
 * @param {object} receiptPrinter Printer that is connected with POS
 * @param {function} updateActivePaymentTabStatus function to update paymenttab status
 * @param {string} orderWindowId The active orderWindows Id
 * @param {function} resetTerminalStateById reset terminal state to idle
 * @param {string} postponedOrderId the postponed order id
 * @param {boolean} optionalReceipts active receipt
 * @param {function} confirmOptionalReceipts function to confirm OptionalReceipt
 */
export const payPostponedCloudPurchase = async (
    payCloudPostponedOrder,
    posOrderInput,
    receiptPrinter,
    updateActivePaymentTabStatus,
    orderWindowId,
    resetTerminalStateById,
    postponedOrderId,
    optionalReceipts,
    confirmOptionalReceipts,
    selectedShop,
    terminalId
) => {
    const {
        data: { savePaidAttemptCloudPostponedOrder }
    } = await payCloudPostponedOrder({
        variables: {
            posOrderInput,
            paymentStatus: PaymentStatus.FullyPaid,
            orderId: postponedOrderId
        },
        errorPolicy: "ignore"
    });
    const { order, terminalResponse } = savePaidAttemptCloudPostponedOrder;

    handleCloudPurchaseResponse(
        order,
        terminalResponse,
        updateActivePaymentTabStatus,
        orderWindowId,
        receiptPrinter,
        resetTerminalStateById,
        terminalId,
        optionalReceipts,
        confirmOptionalReceipts,
        selectedShop
    );
};

/**
 * Function for handle the response from cloud purchases
 *
 * @param {object} order the order response from backend
 * @param {object} terminalResponse the response from backend
 * @param {object} receiptPrinter Printer that is connected with POS
 * @param {function} updateActivePaymentTabStatus function to update paymenttab status
 * @param {string} orderWindowId The active orderWindows Id
 * @param {function} resetTerminalStateById reset terminal state to idle
 * @param {string} terminalId the terminal id
 * @param {boolean} optionalReceipts active receipt
 * @param {function} confirmOptionalReceipts function to confirm OptionalReceipt
 */
const handleCloudPurchaseResponse = async (
    order,
    terminalResponse,
    updateActivePaymentTabStatus,
    orderWindowId,
    receiptPrinter,
    resetTerminalStateById,
    terminalId,
    optionalReceipts,
    confirmOptionalReceipts,
    selectedShop
) => {
    const displayTexts = getActivePaymentTabTexts(order, terminalResponse);
    const { openModal } = modalStore.getState();
    const { selectedPos } = posStore.getState();

    const { preferDigitalReceipts } = selectedPos;

    const restaurantOnlineOrderUrl = selectedShop.settings.onlineSettings.restaurantOnlineOrderUrl ?? null;
    const disableQrOnReceipt = selectedShop.settings.disableQrOnReceipt;
    const customReceiptText = selectedShop.settings.customReceiptText;
    const enablePickUpScreenQrOnReceipt = selectedShop.settings.enablePickUpScreenQrOnReceipt;
    const pickupDisplayUrl = enablePickUpScreenQrOnReceipt && `${getPickupDisplayMobileUrl()}?orderNo=${order.orderNo}`;
    const receiptInfo = {
        restaurantOnlineOrderUrl: restaurantOnlineOrderUrl,
        pickupScreenUrl: pickupDisplayUrl,
        disableQrCode: disableQrOnReceipt,
        customReceiptText: customReceiptText
    };
    updateActivePaymentTabStatus(
        orderWindowId,
        true,
        displayTexts.headerText,
        displayTexts.displayText,
        paymentTabStatusTypes.APPROVED
    );

    const isPrinterAvailable = await pingReceiptPrinter(receiptPrinter);

    if (preferDigitalReceipts) {
        openModal("posDigitalReceiptsModal", {
            order,
            optionalReceipts,
            confirmOptionalReceipts
        });
    } else if (isPrinterAvailable && optionalReceipts) {
        const value = await confirmOptionalReceipts();
        value && printReceipt(order, receiptPrinter, null, null, null, receiptInfo);
    } else {
        printReceipt(order, receiptPrinter, null, null, null, receiptInfo);
    }
    // resetTerminalStateById(terminalId);
};

/**
 * Function for cloud purchase
 *
 * @param {function} createCloudPosOrder mutation to handle the purchase
 * @param {object} posOrderInput the order inpu to mutation
 * @param {object} receiptPrinter Printer that is connected with POS
 * @param {function} updateActivePaymentTabStatus function to update paymenttab status
 * @param {string} orderWindowId The active orderWindows Id
 * @param {function} resetTerminalStateById reset terminal state to idle
 * @param {string} terminalId the terminal id
 * @param {boolean} optionalReceipts active receipt
 * @param {function} confirmOptionalReceipts function to confirm OptionalReceipt
 */
export const cloudPurchase = async (
    createCloudPosOrder,
    posOrderInput,
    receiptPrinter,
    updateActivePaymentTabStatus,
    orderWindowId,
    resetTerminalStateById,
    terminalId,
    optionalReceipts,
    confirmOptionalReceipts,
    selectedShop
) => {
    const {
        data: { purchaseCloudCardPosOrder }
    } = await createCloudPosOrder({
        variables: {
            posOrderInput
        },
        errorPolicy: "ignore"
    });
    const { order, terminalResponse } = purchaseCloudCardPosOrder;

    handleCloudPurchaseResponse(
        order,
        terminalResponse,
        updateActivePaymentTabStatus,
        orderWindowId,
        receiptPrinter,
        resetTerminalStateById,
        terminalId,
        optionalReceipts,
        confirmOptionalReceipts,
        selectedShop
    );
};

export const getPostponedInfo = (activeOrderWindow, activePaymentTab) => {
    if (activeOrderWindow.postponeOrderId) {
        return { orderId: activeOrderWindow.postponeOrderId, postponePayment: true };
    }
    if (activePaymentTab && activePaymentTab.DTInfo.orderId) {
        return { orderId: activePaymentTab.DTInfo.orderId, postponePayment: false };
    }
    return null;
};

/**
 * Function that decides which payment function to be used
 *
 * @param {object} paymentMethodAmount Contains paymentMethod and amount
 * @param {object} paymentTabsUtils Collection of util functions for the active payment tab
 * @param {object} selectedShop The shop that is selected
 * @param {object} selectedPos The pos that is selected
 * @param {object} terminal The terminal to execute card payment on
 * @param {function} addCartToDTPreviousCart add cart to a previous state (in order to make diff)
 * @param {object} extendedProducts products that are going to extend
 * @param {function} extendPostponedPaymentOrder extend a postponed order
 * @param {object} contactInfo info used for parked / postponed orders
 * @param {object} paymentFuncs Contains functions for different mutations
 * @param {object} activePaymentTab the active payment tab
 * @param {function} confirm - await confirm, function to launch modal and await result
 * @param {boolean} manualCardPayment - if card payment is done manually
 * @returns {function}
 */
export const makePayment = async (
    paymentMethodAmount,
    selectedShop,
    selectedPos,
    terminal,
    addCartToDTPreviousCart,
    extendedProducts,
    extendPostponedPaymentOrder,
    paymentFuncs,
    contactInfo,
    awaitConfirm,
    updateOrderWindowPuckNo,
    manualCardPayment,
    splitOrderNo,
    isInSplitMode,
    optionalReceipts,
    confirmOptionalReceipts,
    preferredPuckTab,
    selectedTable,
    upsertOrderWindowMutation,
    isDriveThroughOrder,
    orderWindowtoPay
) => {
    const { getActiveOrderWindow, isLocalOrderWindow, attachContactInformationToPostponedOrderWindow } =
        orderWindowsStore.getState();
    const { queuedOrders, addActivePaymentTab, updateActivePaymentTabStatus, getActivePaymentTab } =
        posStore.getState();

    // Not exactly sure if we should call makePayment without an orderWindowtoPay
    if (!orderWindowtoPay) {
        return null;
    }
    const restaurantOnlineOrderUrl = get(selectedShop, "settings.onlineSettings.restaurantOnlineOrderUrl", null);

    const activePaymentTab = getActivePaymentTab(orderWindowtoPay?.id);
    const postponedInfo = getPostponedInfo(orderWindowtoPay, activePaymentTab);

    let puckNo = orderWindowtoPay?.puckNo;
    if (selectedPos.puckEnabled && !postponedInfo && !isInSplitMode && !orderWindowtoPay?.puckNo) {
        const { cancel, value } = await awaitConfirm({
            component: PuckModal,
            catchOnCancel: true,
            modalContent: {
                puckNo: orderWindowtoPay?.puckNo,
                preferredPuckTab
            }
        });

        updateOrderWindowPuckNo(orderWindowtoPay?.id, value);
        if (cancel) {
            updateActivePaymentTabStatus(orderWindowtoPay?.id, true, "Avbrutet", "", paymentTabStatusTypes.CANCELLED);
            return;
        } else {
            puckNo = value;
        }
    }

    const customOrderNumber = queuedOrders.orderNo ? queuedOrders.orderNo : splitOrderNo;

    const orderInput = buildOrderInput(
        isDriveThroughOrder,
        orderWindowtoPay,
        selectedShop,
        selectedPos.id,
        extendedProducts,
        (postponedInfo && postponedInfo.postponePayment) || paymentMethodAmount.paymentMethod === "POSTPONED",
        puckNo,
        customOrderNumber,
        isInSplitMode,
        selectedTable
    );

    const commonPaymentParams = {
        paymentMethodAmount,
        orderInput,
        updateActivePaymentTabStatus,
        activeOrderWindowId: orderWindowtoPay?.id,
        paymentFuncs,
        receiptPrinter: selectedPos.receiptPrinter,
        postponedInfo,
        restaurantOnlineOrderUrl,
        optionalReceipts,
        confirmOptionalReceipts
    };

    /**
     *  When in splitMode, we don't want to persist split orderWindows since it will override original orderWindow
     *  The orderWindows we want to persist are being handled by SplitCashRegisterProvider
     */
    if (!isInSplitMode && !isLocalOrderWindow(orderWindowtoPay?.id)) {
        upsertOrderWindowMutation({
            variables: {
                orderWindow: { ...orderWindowtoPay, previousCartProducts: orderWindowtoPay?.cartProducts }
            }
        });
    }

    switch (paymentMethodAmount.paymentMethod) {
        case PaymentMethod.CARD:
            if (manualCardPayment) {
                return makeManualCardPayment({ ...commonPaymentParams, terminal });
            } else {
                return makeCardPayment({
                    ...commonPaymentParams,
                    terminal
                });
            }

        case PaymentMethod.CASH:
            return makeCashPayment({ ...commonPaymentParams, awaitConfirm });
        case PaymentMethod.SWISH:
            return makeSwishPayment({ ...commonPaymentParams, awaitConfirm });
        case PaymentMethod.INVOICE:
            return makeInvoicePayment({ ...commonPaymentParams, awaitConfirm });
        case PaymentMethod.POSTPONED: {
            const order = await makePostPonedOrder(
                orderInput,
                paymentFuncs.createPostponedPaymentOrder,
                orderWindowtoPay,
                contactInfo || orderWindowtoPay?.contactInformation
            );
            if (!!order?.failedReason) {
                handleFailedOrder(orderWindowtoPay?.id, order, updateActivePaymentTabStatus);
                return null;
            }
            addActivePaymentTab(
                orderWindowtoPay?.id,
                null,
                paymentTabStatusTypes.POSTPONED_ONGOING,
                false,
                null,
                null,
                true
            );
            attachContactInformationToPostponedOrderWindow(
                orderWindowtoPay.id,
                order.id,
                contactInfo || orderWindowtoPay?.contactInformation
            );
            return order;
        }
        case PaymentMethod.DTADDORDER:
            return createDTOrder(
                orderInput,
                paymentFuncs.createPostponedPaymentOrder,
                addCartToDTPreviousCart,
                addActivePaymentTab,
                orderWindowtoPay.id,
                optionalReceipts,
                confirmOptionalReceipts
            );
        case PaymentMethod.POSTPONED_EXTENDED: {
            const order = await makePostponeExtendOrder(
                orderInput,
                postponedInfo,
                terminal.terminalId,
                extendPostponedPaymentOrder
            );

            if (!order || !!order?.failedReason) {
                handleFailedOrder(orderWindowtoPay.id, order, updateActivePaymentTabStatus);
                return null;
            }

            if (!!orderInput.tableMeta) {
                const table = orderInput.tableMeta;
                updateActivePaymentTabStatus(
                    orderWindowtoPay.id,
                    true,
                    "Utökat",
                    `Utökat för: ${table.name}`,
                    paymentTabStatusTypes.TABLE_EXTENDED
                );
                addCartToDTPreviousCart(orderWindowtoPay.id);
            } else {
                updateActivePaymentTabStatus(
                    orderWindowtoPay.id,
                    true,
                    "Utökat",
                    `Utökat för Order nr: ${order.orderNo}`,
                    paymentTabStatusTypes.POSTPONED_EXTENDED
                );
                addCartToDTPreviousCart(orderWindowtoPay.id);
            }

            return order;
        }
        default:
            break;
    }
};

export const getActivePaymentTabTexts = (order, terminalResponse = null) => {
    let headerText,
        displayText = "";
    if ((terminalResponse && terminalResponse.operationSuccess) || terminalResponse === null) {
        const isCashInvoice = order.orderControlUnitValues && order.orderControlUnitValues.failedRequest;
        headerText = isCashInvoice ? paymentTabsHeaderTexts.CASH_INVOICE : paymentTabsHeaderTexts.APPROVED;
        displayText = isCashInvoice
            ? `${errorConstants.CASH_INVOICE_V2} \n\n\n\n\n\n Order nr ${order.orderNo}`
            : `Order nr ${order.orderNo}`;
    } else {
        headerText = "Misslyckat";
        displayText = terminalResponse ? terminalResponse.errorText : "";
    }

    return { headerText, displayText };
};

/** TEST SUITE */
export const calculateOrderWindowTotalDiscount = cartProducts => {
    return cartProducts
        ? cartProducts.reduce((tot, cp) => {
              tot += cp.orderProduct.discountValue ? cp.orderProduct.discountValue : 0;
              return tot;
          }, 0)
        : 0;
};

export const calculateOrderWindowTotalByFixedDiscount = (cartProducts, fixedDiscountId) => {
    return cartProducts
        ? cartProducts.reduce((totalValue, cart) => {
              if (cart.orderProduct.discountIds?.length > 1) {
                  return totalValue;
              }
              if (!!cart?.fixedDiscount?.fixedDiscountId && cart.fixedDiscount.fixedDiscountId === fixedDiscountId) {
                  totalValue += cart.orderProduct.discountValue ? cart.orderProduct.discountValue : 0;
                  return totalValue;
              }
              return totalValue;
          }, 0)
        : 0;
};

export const hasPriceAdjustDiscounts = cartProducts => {
    return cartProducts.reduce((totalDiscounts, cartProduct) => {
        const { orderProduct } = cartProduct;
        const discount = orderProduct?.combinedDiscounts?.find(
            value => value.discountType === DISCOUNT_TYPE.PRICE_ADJUST
        );
        if (discount) {
            const discountMeta = {
                discountId: discount.discountId,
                name: discount.name,
                code: "",
                discountValue: discount.discountValue
            };
            totalDiscounts = [...totalDiscounts, discountMeta];
        }
        return totalDiscounts;
    }, []);
};

export const cartTotalValues = cartProducts => {
    const originalPrice = getCartTotalOriginalPrice(cartProducts);
    const totalDiscountValue = calculateOrderWindowTotalDiscount(cartProducts);
    const overallDiscountRate = round(totalDiscountValue / originalPrice, 5);
    return {
        originalPrice,
        totalDiscountValue,
        overallDiscountRate
    };
};

export const getGiftCardMeta = (orderWindow, userGiftCard, discountValue = 0, extraCosts = 0) => {
    if (!userGiftCard) {
        return null;
    }

    const { cartProducts } = orderWindow;
    const originalPrice = getCartTotalOriginalPrice(cartProducts);
    const originalPriceWithExtras = originalPrice + extraCosts;
    const priceWithDiscount = originalPriceWithExtras - discountValue;
    const { remainingAmount } = userGiftCard;

    const valueOver = Math.max(0, remainingAmount - priceWithDiscount);
    const giftCardValueUsed = Math.round((remainingAmount - valueOver) * 100) / 100;

    return {
        userGiftCardId: userGiftCard.id,
        amount: giftCardValueUsed
    };
};

/** NOTE test suite */
export const discountToDiscountMeta = orderWindow => {
    const { discount, cartProducts } = orderWindow;
    const priceAdjustDiscounts = hasPriceAdjustDiscounts(cartProducts);

    if (!discount && !priceAdjustDiscounts.length) return null;

    const onlyAppliedDiscount = discount && !priceAdjustDiscounts.length;

    const { originalPrice, totalDiscountValue, overallDiscountRate } = cartTotalValues(cartProducts);

    let discountMeta = [];
    let discountName = "";

    if (discount) {
        const appliedDiscountMeta = {
            discountId: discount.id,
            name: discount.name,
            code: discount?.code ?? "",
            discountValue: totalDiscountValue,
            discountType: DISCOUNT_TYPE.APPLIED_DISCOUNT,
            qoplaSponsored: discount?.qoplaSponsored ?? false
        };
        discountMeta = [appliedDiscountMeta];
        discountName = discount.name;
    }

    if (priceAdjustDiscounts.length) {
        discountMeta = [...discountMeta, ...priceAdjustDiscounts];
        if (discountName) {
            discountName += " & " + priceAdjustDiscounts[0].name;
        } else {
            discountName = priceAdjustDiscounts[0].name;
        }
    }

    if (onlyAppliedDiscount) {
        return {
            id: discount.id,
            discounts: discountMeta,
            name: discount.name,
            amount: discount.amount,
            rate: overallDiscountRate,
            originalPrice: originalPrice,
            totalDiscountValue: totalDiscountValue,
            ...(isCampaignDiscount(discount) && { campaignId: discount.campaignId })
        };
    } else {
        return {
            discounts: discountMeta,
            name: discountName,
            amount: 0,
            rate: overallDiscountRate,
            originalPrice: originalPrice,
            totalDiscountValue: totalDiscountValue,
            ...(isCampaignDiscount(discount) && { campaignId: discount.campaignId })
        };
    }
};

const isCampaignDiscount = discount => {
    return !!discount?.campaignId;
};

/**NOTE TEST SUITE */
export const setAllDiscountsToDiscountMeta = orderWindow => {
    const { discount, cartProducts, fixedDiscounts } = orderWindow;

    if (!fixedDiscounts && !fixedDiscounts.length) return null;

    // Needed because of fixed subscriptions discounts
    const _fixedDiscounts = uniqBy(fixedDiscounts, "name");

    let discounts = [..._fixedDiscounts];
    if (discount) {
        discounts = [...discounts, discount];
    }

    const discountName = discounts.map(discount => discount.name).join(" & ");
    const originalPrice = getCartTotalOriginalPrice(cartProducts);
    const totalDiscountValue = calculateOrderWindowTotalDiscount(cartProducts);
    const discountRate = totalDiscountValue / originalPrice;

    const discountMeta = {
        discounts: totalDiscounts(discounts, cartProducts),
        name: discountName,
        amount: 0,
        rate: round(discountRate, 2),
        originalPrice: originalPrice,
        totalDiscountValue: totalDiscountValue,
        ...(isCampaignDiscount(discount) && { campaignId: discount.campaignId })
    };

    return discountMeta;
};

/** TEST SUITE */
export const setDiscountAndComboDiscountMeta = orderWindow => {
    const { discount, cartProducts, comboDiscounts } = orderWindow;

    if (!comboDiscounts && !comboDiscounts.length) return null;

    let discounts = [...comboDiscounts];
    if (discount) {
        discounts = [...discounts, discount];
    }

    const priceAdjustDiscounts = hasPriceAdjustDiscounts(cartProducts);
    const originalPrice = getCartTotalOriginalPrice(cartProducts);
    const totalDiscountValue = calculateOrderWindowTotalDiscount(cartProducts);
    const discountRate = totalDiscountValue / originalPrice;
    let discountName = discounts.map(discount => discount.name).join(" & ");

    let totalComboDiscounts = totalComboDiscount(discounts, cartProducts);

    if (priceAdjustDiscounts.length) {
        totalComboDiscounts = [...totalComboDiscounts, ...priceAdjustDiscounts];
    }
    const priceAdjustName = priceAdjustDiscounts.length ? ` & ${priceAdjustDiscounts[0].name}` : "";
    discountName += priceAdjustName;

    const discountMeta = {
        discounts: totalComboDiscounts,
        name: discountName,
        amount: 0,
        rate: round(discountRate, 2),
        originalPrice: originalPrice,
        totalDiscountValue: totalDiscountValue
    };

    return discountMeta;
};

export const totalComboDiscount = (allDiscounts, cartProducts) => {
    return allDiscounts.reduce((totalDiscounts, discount) => {
        const total = {
            discountId: discount.id,
            name: discount.name,
            code: discount?.code ?? "",
            discountValue: 0
        };

        total.discountValue = cartProducts.reduce((total, cartProduct) => {
            const { orderProduct } = cartProduct;
            if (orderProduct.combinedDiscounts) {
                const orderDiscountValue = orderProduct.combinedDiscounts.reduce((totalDiscount, combo) => {
                    if (combo.discountId === discount.id) {
                        return (totalDiscount += combo.discountValue);
                    }
                    return totalDiscount;
                }, 0);
                return (total += orderDiscountValue);
            }
            return total;
        }, 0);

        if (total.discountValue === 0) {
            return [...totalDiscounts];
        }

        return [...totalDiscounts, total];
    }, []);
};

export const getOnlineDiscountType = combinedDiscounts => {
    if (!!combinedDiscounts?.length) {
        const latestAddedDiscountIdx = combinedDiscounts.length - 1;

        return combinedDiscounts[latestAddedDiscountIdx].discountType;
    }

    return null;
};

/**NOTE ALSO IN TEST SUITE */
export const totalDiscounts = (allDiscounts, cartProducts) => {
    return allDiscounts.reduce((totalDiscounts, discount) => {
        const isSubscriptionDiscount = discount.type === DISCOUNT_TYPE.SUBSCRIPTION_DISCOUNT;

        const total = {
            discountId: isSubscriptionDiscount ? "" : discount.id,
            subscriptionDiscountId: isSubscriptionDiscount ? discount.id : "",
            name: discount.name,
            code: discount?.code ?? "",
            discountValue: 0,
            discountType: null,
            qoplaSponsored: discount?.qoplaSponsored ?? false
        };

        cartProducts.forEach(cart => {
            const { orderProduct } = cart;
            if (orderProduct.discountIds?.includes(discount.id)) {
                if (orderProduct.discountIds?.length === 1) {
                    total.discountValue += orderProduct.discountValue;
                } else if (orderProduct.discountIds?.length > 1) {
                    const orderDiscount = orderProduct.combinedDiscounts.find(
                        combined => combined.discountId === discount.id
                    );
                    total.discountValue += orderDiscount.discountValue;
                }

                total.discountType = getOnlineDiscountType(cart.orderProduct.combinedDiscounts);
            }
        });

        return [...totalDiscounts, total];
    }, []);
};
