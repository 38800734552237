export const isMenuBundleProduct = product => {
    return !!product.refBundleProduct;
};

export const hasModifications = menuProduct => {
    return !!menuProduct.refProduct.modifications || !!menuProduct.modifications;
};

/**@deprecated - GOTO HASH VALUE HELPERS */
export const isValidUUID = id => {
    const uuidValidator = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidValidator.test(id);
};
